import { IPromo, IPromoProduct } from '@/types/interfaces/common';
import {
	AdditiveGroupWithSelected,
	AdditiveGroupsCache,
	IProduct,
	IProductsCategory
} from '@/types/interfaces/menu';
import { ADDITIVE_STATUSES } from '@/types/enums/menu';

export enum KEYS {
	CART = 'cart',
	TEMP_TERMINALS = 'tempTerminals',
	MENU_TEMP_DATA = 'menuTempData'
}

export enum PROMO_TYPES {
	FULL_MENU = 'full_menu',
	SEPARATE_POSITION = 'separate_position'
}

export interface ICustomCategory {
	id: number;
	translateKey: string;
}

export interface ITempFilialData {
	source: string;
	filialId: number;
	table:
		| {
				id: number;
				name: string;
		  }
		| undefined;
}

export interface IMenuState {
	menu: IProductsCategory[];
	externalId?: string;
	recentTerminalId: number;
	additiveGroupsCache: {
		[key in ADDITIVE_STATUSES]: AdditiveGroupsCache;
	};
	promos: IPromo[];
	promoProducts: IPromoProduct[];
}

export interface IMenuService {
	state: IMenuState;
	fetchMenu: (
		filialId: number,
		force?: boolean,
		abort?: AbortController
	) => Promise<{
		categories: IProductsCategory[];
		promos: IPromo[];
		externalId?: string;
	}>;
	fetchPromo: (
		filialId: number
	) => Promise<{ promos: IPromo[]; promoProducts: IPromoProduct[] }>;
	getAdditiveGroups: (
		productId: number,
		status?: ADDITIVE_STATUSES
	) => AdditiveGroupWithSelected;
	fetchAdditiveGroups: (
		product: IProduct,
		filialId: number,
		serviceType: number,
		status?: ADDITIVE_STATUSES
	) => Promise<AdditiveGroupWithSelected | undefined>;
	clearMenu: () => void;
	clearAdditiveGroups: () => void;
	fetchRecommendedProducts: () => Promise<IProduct[]>;
}
