import {
	authorize,
	backToApplication,
	applicationBecomeActive,
	handleNavigationToBack,
	whenNavigationToBack,
	cancelHandlingToBack,
	clearCache,
	hapticSelection,
	openQR
} from 'choco-app';
import CommmonApplication from './common-application.provider';
import { AuthService } from '@/services/auth';
import {
	AUTH_BY_CODE_ERROR,
	AUTH_BY_TRACK_ERROR,
	CLIENT_ID,
	SERVICES
} from '@/utils/constants';
import { TCallback } from '@/utils/plugins/di/types';
import { APP_RUNTIME } from '@/types/enums/common';
import { IService } from '@/types/interfaces/common';

class WebViewApplication extends CommmonApplication {
	constructor(runtimeName: APP_RUNTIME) {
		super(runtimeName);
	}

	backToApp() {
		backToApplication();
	}

	becomingActive(cb: TCallback) {
		applicationBecomeActive(cb);
	}

	becomingHidden() {
		applicationBecomeActive(() => {});
	}

	ifCoordinatesGranted(cb: TCallback) {
		this.becomingActive(cb);
	}

	handleNavigationToBack() {
		handleNavigationToBack();
	}

	/**
	 * Действие - что делаем, когда юзер переходит назад
	 * @param {function} cb
	 * */
	whenNavigationToBack(cb: TCallback) {
		whenNavigationToBack(cb);
	}

	cancelHandlingToBack() {
		cancelHandlingToBack();
	}

	async authorize(): Promise<boolean> {
		try {
			const code = await authorize(CLIENT_ID, 'code', AuthService.getAuthUrl());

			if (code) {
				return await AuthService.authByCode(code);
			}

			return Promise.reject(new Error(AUTH_BY_CODE_ERROR));
		} catch (error: any) {
			if (error.message === 'NOT_SUPPORTED') {
				const trackId = await authorize(CLIENT_ID, 'trackId');

				if (trackId) {
					return await AuthService.authByTrackId(trackId);
				}

				return Promise.reject(new Error(AUTH_BY_TRACK_ERROR));
			}

			return Promise.reject(error);
		}
	}

	clearCache() {
		return clearCache();
	}

	haptic(_type: string) {
		return hapticSelection();
	}

	openQR = (): Promise<string> => {
		return openQR();
	};

	openExternalLink = (url: string): void => {
		window.location.href = url;
	};

	getServiceById(id: string): IService {
		return this.findServiceById(id) || SERVICES.takeaway;
	}
}

export default new WebViewApplication(APP_RUNTIME.WEB_VIEW);
